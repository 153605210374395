<template>
  <div>
    <van-divider>基本信息</van-divider>
    <van-form @submit="handleSubmit">
      <van-field label="联系人" name="contactName" v-model="formData.contactName" placeholder="联系人" :rules="rules"></van-field>
      <van-field label="电话" name="phone" v-model="formData.phone" placeholder="请输入电话" :rules="rules"></van-field>
      <van-field label="公司名称" name="companyName" v-model="formData.companyName" placeholder="请输入公司名称" :rules="rules"></van-field>
      <van-field label="产品名称" name="product" v-model="formData.product" placeholder="请输入产品名称" :rules="rules"></van-field>
      <van-field label="备注" name="remark" v-model="formData.remark" placeholder="请输入备注" :rules="rules"></van-field>
      <el-row type="flex" justify="space-around" style="margin-top:20px">
        <el-col :span="11">
          <van-button type="info" block native-type="submit">提交</van-button>
        </el-col>
        <el-col :span="11">
          <van-button type="primary" block to="WapMyCustomerService">我的售后</van-button>
        </el-col>
      </el-row>
    </van-form>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
Vue.use(Vant);
export default {
  data() {
    return {
      popupVisible: false,
      formData: {},
      rules: [{ required: true, message: "必填", trigger: "onChange" }],
      columnsArea: [],
    };
  },
  methods: {
    handleSubmit() {
      let that = this;
      that.axios
        .post("CustomerService/AddModel", that.formData)
        .then(function () {
          that.$router.push("WapMyCustomerService");
        });
    },
  },
  mounted() {
    document.title = "售后登记";
  },
};
</script>

<style>
</style>